import { styled, Typography, useMediaQuery } from "@mui/material"
import {
  StyledBlobContentContainer,
  StyledPageSplitStack,
  StyledBlobContainer,
} from "./SharedStyledComponents"
import { theme } from "../ui/materialUITheme"
import { ReactNode } from "react"
import SvgBlobFirstOption from "../assets/blobs/blob-option-1-cropped.svg"
import SvgBlobFirstOptionGrey from "../assets/blobs/blob-option-1-cropped-grey.svg"
import { useASMLConfiguration } from "../ui/addCompanyStyles"

type FirstOptionBlobSlideProps = {
  title: string
  children: ReactNode
}

export const FirstOptionBlobSlide = ({
  title,
  children,
}: FirstOptionBlobSlideProps): JSX.Element => {
  const smallDevice = useMediaQuery(theme.breakpoints.down("lg"))

  const titleEl = <StyledTitle variant="h2">{title}</StyledTitle>

  return (
    <StyledPageSplitStack>
      {!smallDevice && titleEl}

      <StyledFirstOptionBlobContainer>
        <StyledFirstOptionBlobContentContainer>
          {smallDevice && titleEl}
          {children}
        </StyledFirstOptionBlobContentContainer>
      </StyledFirstOptionBlobContainer>
    </StyledPageSplitStack>
  )
}

const StyledTitle = styled(Typography)`
  flex-basis: 40%;
  text-align: center;
  padding: 20px;

  ${theme.breakpoints.down("lg")} {
    flex-basis: fit-content;
  }

  ${theme.breakpoints.between("md", "lg")} {
    margin-top: 50px;
  }
`

const StyledFirstOptionBlobContainer = styled(StyledBlobContainer)`
  max-width: 1600px;
  background-image: url(${useASMLConfiguration
    ? SvgBlobFirstOptionGrey
    : SvgBlobFirstOption});
  background-size: 70%;
  flex-basis: 60%;
  transition: 0.5s all ease;

  ${theme.breakpoints.down("lg")} {
    flex-grow: 1;
  }

  ${theme.breakpoints.down("md")} {
    background-size: 100%;
  }
`

const StyledFirstOptionBlobContentContainer = styled(
  StyledBlobContentContainer,
)`
  overflow: scroll;
  padding: 20px;
  max-height: 100vh;

  ${theme.breakpoints.down("md")} {
    width: 90%;
  }
`
