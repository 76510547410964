import { Button, styled, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { theme } from "../ui/materialUITheme"
import logo from "./../assets/img/uButler_Icon_450x450.png"
import logoASML from "./../assets/img/ASML_logo.png"
import { useASMLConfiguration } from "../ui/addCompanyStyles"

export const IntroductionComponent = (): JSX.Element => {
  return (
    <>
      <div>
        <Typography variant="h2">Welcome!</Typography>
        <StyledIntroduction variant="body1">
          {
            "Finding housing in a new country can be difficult. Especially when you don't know what to expect. This tool will help you get a better idea of the housing options available to you. Ready to start?"
          }
        </StyledIntroduction>
        <Button
          variant="outlined"
          color="inherit"
          component={Link}
          to={useASMLConfiguration ? "/options" : "/region"}
        >
          {"Let's go"}
        </Button>
      </div>
      {useASMLConfiguration && <StyledASMLLogo src={logoASML} />}
      {!useASMLConfiguration && <StyledLogo src={logo} />}
    </>
  )
}

const StyledLogo = styled("img")`
  width: 140px;

  ${theme.breakpoints.down("sm")} {
    width: 120px;
  }
`

const StyledASMLLogo = styled("img")`
  width: 240px;

  ${theme.breakpoints.down("md")} {
    width: 200px;
    ${useASMLConfiguration && "align-self: flex-start;"}
  }
`

const StyledIntroduction = styled(Typography)`
  margin: 5px 0 15px;
`
