import { Mark } from "@mui/base"
import { useHousing } from "../providers/housingState"
import { CustomSlider } from "./CustomSlider"

export const bedroomMarks: Mark[] = [
  {
    value: 0,
    label: "Studio",
  },
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
]

export const BedroomsSlider = (): JSX.Element => {
  const { bedrooms, setBedrooms } = useHousing()

  return (
    <CustomSlider
      value={bedrooms}
      onChange={setBedrooms}
      marks={bedroomMarks}
      min={0}
      max={4}
    />
  )
}
