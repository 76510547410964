import { createTheme } from "@mui/material"

const createMUITheme = () => {
  const original = createTheme({
    palette: {
      primary: {
        main: "#2a2135",
        contrastText: "#ffffff",
      },
    },
    typography: {
      fontFamily: "Muli",
      h2: {
        fontWeight: "800",
      },
      h6: {
        fontWeight: "800",
        textAlign: "center",
      },
      body1: {
        fontWeight: "300",
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1400, // Changed
      },
    },
  })
  return original
}

export const theme = createMUITheme()
