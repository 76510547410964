import React, { createContext, useContext, useState } from "react"

type PersonalInformationStateProps = {
  fullName: string | null
  setFullName: (newFullName: string | null) => void
  preferredArea: string | null
  setPreferredArea: (newPreferredArea: string | null) => void
  additionalWishes: string | null
  setAdditionalWishes: (newAdditionalWishes: string | null) => void
}

const LocalStateContext = createContext({} as PersonalInformationStateProps)

type PersonalInformationStateProviderProps = {
  children: React.ReactNode
}

const PersonalInformationStateProvider = ({
  children,
}: PersonalInformationStateProviderProps): JSX.Element => {
  const [fullName, setFullName] = useState<string | null>("")
  const [preferredArea, setPreferredArea] = useState<string | null>("")
  const [additionalWishes, setAdditionalWishes] = useState<string | null>("")

  const value: PersonalInformationStateProps = {
    fullName,
    setFullName,
    preferredArea,
    setPreferredArea,
    additionalWishes,
    setAdditionalWishes,
  }
  return (
    <LocalStateContext.Provider value={value}>
      {children}
    </LocalStateContext.Provider>
  )
}

const usePersonalInformation = (): PersonalInformationStateProps => {
  return useContext(LocalStateContext)
}

export { PersonalInformationStateProvider, usePersonalInformation }
