import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination } from "swiper"
import { BudgetSlide } from "../components/slides/BudgetSlide"

import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "./SwiperPage.css"
import { BedroomsSlide } from "../components/slides/BedroomsSlide"
import { SquareMetersSlide } from "../components/slides/SquareMetersSlide"
import { FurnishedSlide } from "../components/slides/FurnishedSlide"
import { OutsideSpaceSlide } from "../components/slides/OutsideSpaceSlide"
import { useWindowWidth } from "../hooks/useWindowWidth"
import { theme } from "../ui/materialUITheme"
import { CopyrightFootnote } from "../components/CopyrightFootnote"

export type SlideProps = {
  onNextButtonClicked: () => void
}

export const SwiperPage = () => {
  const windowWidth = useWindowWidth()
  if (windowWidth === 0) return <></>

  const allowTouchMove = windowWidth < theme.breakpoints.values.md

  return (
    <>
      <Swiper
        direction={"vertical"}
        slidesPerView={1}
        speed={700}
        pagination={{
          clickable: false,
        }}
        allowTouchMove={allowTouchMove}
        modules={[Pagination]}
        className="optionsSwiper"
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <SwiperSlide>
          <BudgetSlide />
        </SwiperSlide>
        <SwiperSlide>
          <BedroomsSlide />
        </SwiperSlide>
        <SwiperSlide>
          <SquareMetersSlide />
        </SwiperSlide>
        <SwiperSlide>
          <FurnishedSlide />
        </SwiperSlide>
        <SwiperSlide>
          <OutsideSpaceSlide />
        </SwiperSlide>
      </Swiper>
      <CopyrightFootnote />
    </>
  )
}
