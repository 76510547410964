import { ThemeProvider } from "@mui/material"
import { HousingStateProvider } from "./providers/housingState"
import { PersonalInformationStateProvider } from "./providers/personalInformationState"
import { Routes } from "./router/Routes"
import { theme } from "./ui/materialUITheme"

function App() {
  return (
    <ThemeProvider theme={theme}>
      <HousingStateProvider>
        <PersonalInformationStateProvider>
          <Routes />
        </PersonalInformationStateProvider>
      </HousingStateProvider>
    </ThemeProvider>
  )
}

export default App
