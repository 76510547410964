import { SwiperButtons } from "../SwiperButtons"
import { BedroomsSlider } from "../BedroomsSlider"
import { useHousing } from "../../providers/housingState"
import { slideContent } from "../../assets/content/slideContent"
import { SecondOptionBlobSlide } from "../SecondOptionBlobSlide"
import { SlideBodyContent } from "../SlideBodyContent"
import { SlideBodyCTA } from "../SlideBodyCTA"

export const BedroomsSlide = (): JSX.Element => {
  const { region } = useHousing()

  if (!region) return <></>

  return (
    <SecondOptionBlobSlide title="Types of houses 🛏">
      <SlideBodyContent>{slideContent[region].bedrooms}</SlideBodyContent>
      <SlideBodyCTA>How many bedrooms would you like to have?</SlideBodyCTA>
      <BedroomsSlider />
      <SwiperButtons showPrevious={true} showNext={true} />
    </SecondOptionBlobSlide>
  )
}
