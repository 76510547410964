import { Button, ButtonGroup, styled } from "@mui/material"
import { useSwiper } from "swiper/react"
import { useNavigate } from "react-router-dom"

type SwiperButtonsProps = {
  showPrevious?: boolean
  showNext?: boolean
  showFinish?: boolean
}

export const SwiperButtons = ({
  showPrevious,
  showNext,
  showFinish,
}: SwiperButtonsProps): JSX.Element => {
  const swiper = useSwiper()
  const navigation = useNavigate()

  const finishHandler = () => {
    navigation({ pathname: "/result" })
  }

  return (
    <ButtonGroupStyles variant="contained" color="inherit">
      {showPrevious && (
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => {
            swiper.slidePrev()
          }}
        >
          Previous
        </Button>
      )}
      {showNext && (
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => {
            swiper.slideNext()
          }}
        >
          Next
        </Button>
      )}
      {showFinish && (
        <Button variant="outlined" color="inherit" onClick={finishHandler}>
          Finish
        </Button>
      )}
    </ButtonGroupStyles>
  )
}

const ButtonGroupStyles = styled(ButtonGroup)`
  margin-top: 20px;
  .MuiButtonGroup-grouped:not(:last-of-type) {
    border-right: 0;
  }
`
