import { Mark } from "@mui/base"
import { useHousing } from "../providers/housingState"
import { CustomSlider } from "./CustomSlider"

const budgetMarks: Mark[] = [
  {
    value: 500,
    label: "€500",
  },
  {
    value: 750,
  },
  {
    value: 1000,
    label: "€1000",
  },
  {
    value: 1250,
  },
  {
    value: 1500,
    label: "€1500",
  },
  {
    value: 1750,
  },
  {
    value: 2000,
    label: "€2000",
  },
  {
    value: 2250,
  },
  {
    value: 2500,
    label: "€2500",
  },
  {
    value: 2750,
  },
  {
    value: 3000,
    label: "€3000",
  },
]

export const BudgetSlider = (): JSX.Element => {
  const { budget, setBudget } = useHousing()

  return (
    <CustomSlider
      value={budget}
      onChange={setBudget}
      marks={budgetMarks}
      min={500}
      max={3000}
      prefix="€"
    />
  )
}
