import { Region } from "../../providers/housingState"

type SlideContent = {
  bedrooms: string
  budget: string
  furnished: string | JSX.Element
  squareMeters: string
  outsideSpace: string
  result: string | JSX.Element
}

export const slideContent: Record<Region, SlideContent> = {
  Amsterdam: {
    bedrooms:
      "The amount of bedrooms might be hard to choose. It is understandable that you might want some extra space, like a spare bedroom or an office. Since the average home in Amsterdam is only 49 square meters this might result in a long and impossible search.\n\nPlease takes this into account and set the amount of bedrooms you would like 😄",
    budget:
      "The Dutch real estate market is tight and extremely competitive at the moment. House prices have never been as high as in 2023 and overbidding on a house is the rule rather than the exception. Rental prices are soaring, so setting a budget for your new home isn't an easy task.\n\nTo give an example, the average housing price in Amsterdam for a 100m2 apartment is € 2.018,- per month excluding utilities. Please set your own budget to find out what your changes are of finding a home soon 🚀",
    furnished: (
      <>
        Houses in the Netherlands can be offered to future tenants in 3
        different ways: <strong>unfurnished</strong>,{" "}
        <strong>upholstered</strong> or <strong>furnished</strong>. Unfurnished
        means that there is only a concrete floor, bare wall and ceilings.
        Upholstered means that there are already carpets or wooden floors,
        curtains and wallpaper or painted walls. Furnished means that all the
        basic things are present.
        <br />
        <br />
        <strong>Good to know</strong>: Furnished houses are very limited. It
        makes the search in this dynamic housing market even more difficult and
        long. There is also a price difference. The difference in price depends
        on how luxurious the furniture is. Take into account that you&apos;ll
        spend around a €100 - €300 extra for a furnished apartment.
      </>
    ),
    squareMeters:
      "The average amount of square meters for a one-bedroom apartment in Amsterdam is 35 m2. For a two bedroom apartment this is 65m2!\n\nPlease fill in the amount of square meters you would like your future house to be 🙂",
    outsideSpace:
      'An outside area to your house or apartment is really seen as "extra" in the Netherlands. If you would love to have a balcony or a garden, you can expect the monthly rent to increase significantly. The amount will depend on how big the garden or balcony is 😄',
    result: (
      <>
        Thanks! On the map, you&apos;ll see how your choices impact your chances
        for finding a house in each different area in Amsterdam. You can play
        around with the different options to see how you could improve your
        chances.{" "}
        <strong>
          Please note that we can not guarantee the availability of (enough)
          housing options, even for areas where there are usually more options
          available.
        </strong>
      </>
    ),
  },
  Eindhoven: {
    bedrooms:
      "The amount of bedrooms might be hard to choose. It is understandable that you might want some extra space, like a spare bedroom or an office. Since the average home in Eindhoven is only 69 square meters this might result in a long and impossible search.\n\nPlease takes this into account and set the amount of bedrooms you would like 😄",
    budget:
      "The Dutch real estate market is tight and extremely competitive at the moment. House prices have never been as high as in 2023 and overbidding on a house is the rule rather than the exception. Rental prices are soaring, so setting a budget for your new home isn't an easy task.\n\nTo give an example, the average housing price in Eindhoven for a 100m2 apartment is € 1576,- per month excluding utilities. Please set your own budget to find out what your changes are of finding a home soon 🚀",
    furnished: (
      <>
        Houses in the Netherlands can be offered to future tenants in 3
        different ways: <strong>unfurnished</strong>,{" "}
        <strong>upholstered</strong> or <strong>furnished</strong>. Unfurnished
        means that there is only a concrete floor, bare wall and ceilings.
        Upholstered means that there are already carpets or wooden floors,
        curtains and wallpaper or painted walls. Furnished means that all the
        basic things are present.
        <br />
        <br />
        <strong>Good to know</strong>: Furnished houses are very limited. It
        makes the search in this dynamic housing market even more difficult and
        long. There is also a price difference. The difference in price depends
        on how luxurious the furniture is. Take into account that you&apos;ll
        spend around a €100 - €300 extra for a furnished apartment.
      </>
    ),
    squareMeters:
      "The average amount of square meters for a one-bedroom apartment in Eindhoven is 55 m2  For a two bedroom apartment this is 90m2!\n\nPlease fill in the amount of square meters you would like your future house to be 🙂",
    outsideSpace:
      'An outside area to your house or apartment is really seen as "extra" in the Netherlands. If you would love to have a balcony or a garden, you can expect the monthly rent to increase significantly. The amount will depend on how big the garden or balcony is 😄',
    result: (
      <>
        Thanks! On the map, you&apos;ll see how your choices impact your chances
        for finding a house in each different area in Eindhoven. You can play
        around with the different options to see how you could improve your
        chances.{" "}
        <strong>
          Please note that we can not guarantee the availability of (enough)
          housing options, even for areas where there are usually more options
          available.
        </strong>
      </>
    ),
  },
  Utrecht: {
    bedrooms:
      "The amount of bedrooms might be hard to choose. It is understandable that you might want some extra space, like a spare bedroom or an office. Since the average home in Utrecht is only 75 square meters this might result in a long and impossible search.\n\nPlease takes this into account and set the amount of bedrooms you would like 😄",
    budget:
      "The Dutch real estate market is tight and extremely competitive at the moment. House prices have never been as high as in 2023 and overbidding on a house is the rule rather than the exception. Rental prices are soaring, so setting a budget for your new home isn't an easy task.\n\nTo give an example, the average housing price in Utrecht is € 1445,- per month excluding utilities. Please set your own budget to find out what your changes are of finding a home soon 🚀",
    furnished: (
      <>
        Houses in the Netherlands can be offered to future tenants in 3
        different ways: <strong>unfurnished</strong>,{" "}
        <strong>upholstered</strong> or <strong>furnished</strong>. Unfurnished
        means that there is only a concrete floor, bare wall and ceilings.
        Upholstered means that there are already carpets or wooden floors,
        curtains and wallpaper or painted walls. Furnished means that all the
        basic things are present.
        <br />
        <br />
        <strong>Good to know</strong>: Furnished houses are very limited. It
        makes the search in this dynamic housing market even more difficult and
        long. There is also a price difference. The difference in price depends
        on how luxurious the furniture is. Take into account that you&apos;ll
        spend around a €100 - €300 extra for a furnished apartment.
      </>
    ),
    squareMeters:
      "The average amount of square meters for an apartment in Utrecht is 75 m2. Please fill in the amount of square meters you would like your future house to be 🙂",
    outsideSpace:
      'An outside area to your house or apartment is really seen as "extra" in the Netherlands. If you would love to have a balcony or a garden, you can expect the monthly rent to increase significantly. The amount will depend on how big the garden or balcony is 😄',
    result: (
      <>
        Thanks! On the map, you&apos;ll see how your choices impact your chances
        for finding a house in each different area in Utrecht. You can play
        around with the different options to see how you could improve your
        chances.{" "}
        <strong>
          Please note that we can not guarantee the availability of (enough)
          housing options, even for areas where there are usually more options
          available.
        </strong>
      </>
    ),
  },
  Rotterdam: {
    bedrooms:
      "The amount of bedrooms might be hard to choose. It is understandable that you might want some extra space, like a spare bedroom or an office. Since the average home in Rotterdam is only 80 square meters this might result in a long and impossible search.\n\nPlease takes this into account and set the amount of bedrooms you would like 😄",
    budget:
      "The Dutch real estate market is tight and extremely competitive at the moment. House prices have never been as high as in 2023 and overbidding on a house is the rule rather than the exception. Rental prices are soaring, so setting a budget for your new home isn't an easy task.\n\nTo give an example, the average housing price in Rotterdam is € 1390,- per month excluding utilities. Please set your own budget to find out what your changes are of finding a home soon 🚀",
    furnished: (
      <>
        Houses in the Netherlands can be offered to future tenants in 3
        different ways: <strong>unfurnished</strong>,{" "}
        <strong>upholstered</strong> or <strong>furnished</strong>. Unfurnished
        means that there is only a concrete floor, bare wall and ceilings.
        Upholstered means that there are already carpets or wooden floors,
        curtains and wallpaper or painted walls. Furnished means that all the
        basic things are present.
        <br />
        <br />
        <strong>Good to know</strong>: Furnished houses are very limited. It
        makes the search in this dynamic housing market even more difficult and
        long. There is also a price difference. The difference in price depends
        on how luxurious the furniture is. Take into account that you&apos;ll
        spend around a €100 - €300 extra for a furnished apartment.
      </>
    ),
    squareMeters:
      "The average amount of square meters for an apartment in Rotterdam is 80 m2. Please fill in the amount of square meters you would like your future house to be 🙂",
    outsideSpace:
      'An outside area to your house or apartment is really seen as "extra" in the Netherlands. If you would love to have a balcony or a garden, you can expect the monthly rent to increase significantly. The amount will depend on how big the garden or balcony is 😄',
    result: (
      <>
        Thanks! On the map, you&apos;ll see how your choices impact your chances
        for finding a house in each different area in Rotterdam. You can play
        around with the different options to see how you could improve your
        chances.{" "}
        <strong>
          Please note that we can not guarantee the availability of (enough)
          housing options, even for areas where there are usually more options
          available.
        </strong>
      </>
    ),
  },
}
