import { Box, Card, CardContent, styled, Typography } from "@mui/material"
import { slideContent } from "../assets/content/slideContent"
import { InteractiveMap } from "../components/InteractiveMap"
import { BedroomsSlider } from "../components/BedroomsSlider"
import { BudgetSlider } from "../components/BudgetSlider"
import { FurnishedCheckbox } from "../components/FurnishedCheckbox"
import { SquareMetersSlider } from "../components/SquareMetersSlider"
import { useHousing } from "../providers/housingState"
import { theme } from "../ui/materialUITheme"
import { CopyrightFootnote } from "../components/CopyrightFootnote"

export const ResultPage = (): JSX.Element => {
  const { region } = useHousing()

  if (!region) return <></>

  return (
    <>
      <StyledPageSplitStack
        sx={{
          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            gap: "20px",
            padding: "30px",
          },
        }}
      >
        <Box sx={{ flexBasis: "40%" }}>
          <Typography variant="h2" gutterBottom>
            Your results
          </Typography>

          <Card>
            <CardContent>
              <Typography variant="body1" sx={{ margin: "5px 0 15px" }}>
                {slideContent[region].result}
              </Typography>
              <Typography
                variant="body1"
                component="h3"
                sx={{ margin: "5px 0 15px", fontWeight: "bold" }}
              >
                Budget
              </Typography>
              <Box sx={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <BudgetSlider />
              </Box>

              <Typography
                variant="body1"
                component="h3"
                sx={{ margin: "5px 0 15px", fontWeight: "bold" }}
              >
                Bedrooms
              </Typography>
              <Box sx={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <BedroomsSlider />
              </Box>

              <Typography
                variant="body1"
                component="h3"
                sx={{ margin: "5px 0 15px", fontWeight: "bold" }}
              >
                Square meters
              </Typography>
              <Box sx={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <SquareMetersSlider />
              </Box>

              <Typography
                variant="body1"
                component="h3"
                sx={{ margin: "5px 0 5px", fontWeight: "bold" }}
              >
                Furnished
              </Typography>
              <FurnishedCheckbox />
            </CardContent>
          </Card>
        </Box>

        <Box
          sx={{
            flexBasis: "50%",
            [theme.breakpoints.down("md")]: {
              width: "100%",
            },
          }}
        >
          <Card>
            <InteractiveMap />
          </Card>
        </Box>

        <Box
          sx={{
            width: "100%",
            position: "relative",
            paddingTop: "50px",
          }}
        >
          <CopyrightFootnote />
        </Box>
      </StyledPageSplitStack>
    </>
  )
}

const StyledPageSplitStack = styled("div")`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  min-height: 100vh;
  background-color: #f7f7f7;
  padding: 50px 20px;
`
