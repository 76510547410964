import { Box, styled } from "@mui/material"

export const StyledPageSplitStack = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
  max-width: 1800px;
`

export const StyledBlobContainer = styled(Box)`
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
`

export const StyledBlobContentContainer = styled(Box)`
  width: 60%;
`
