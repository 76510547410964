import {
  BrowserRouter as Router,
  Routes as ReactRouterRoutes,
  Route,
} from "react-router-dom"
import { IntroductionPage } from "../pages/IntroductionPage"
import { ResultPage } from "../pages/ResultPage"
import { SwiperPage } from "../pages/SwiperPage"

export const Routes = (): JSX.Element => {
  return (
    <Router>
      <ReactRouterRoutes>
        <Route path="/" element={<IntroductionPage />} />
        <Route path="/introduction" element={<IntroductionPage />} />
        <Route path="/region" element={<IntroductionPage />} />
        <Route path="/options" element={<SwiperPage />} />
        <Route path="/result" element={<ResultPage />} />
      </ReactRouterRoutes>
    </Router>
  )
}
