import { SwiperButtons } from "../SwiperButtons"
import { SquareMetersSlider } from "../SquareMetersSlider"
import { useHousing } from "../../providers/housingState"
import { slideContent } from "../../assets/content/slideContent"
import { FirstOptionBlobSlide } from "../FirstOptionBlobSlide"
import { SlideBodyContent } from "../SlideBodyContent"
import { SlideBodyCTA } from "../SlideBodyCTA"

export const SquareMetersSlide = (): JSX.Element => {
  const { region } = useHousing()

  if (!region) return <></>

  return (
    <FirstOptionBlobSlide title="Does it measure up? 📏">
      <SlideBodyContent>{slideContent[region].squareMeters}</SlideBodyContent>
      <SlideBodyCTA>How many square meters should your home be?</SlideBodyCTA>
      <SquareMetersSlider />
      <SwiperButtons showPrevious={true} showNext={true} />
    </FirstOptionBlobSlide>
  )
}
