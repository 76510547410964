import { Button, Stack, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { Region, useHousing } from "../providers/housingState"

export const RegionChoiceComponent = (): JSX.Element => {
  const { setRegion } = useHousing()
  const navigation = useNavigate()

  const handleButtonClick = (region: Region) => {
    setRegion(region)
    navigation({ pathname: "/options" })
  }

  return (
    <>
      <Typography variant="h6" component="h3" sx={{ margin: "5px 0 15px" }}>
        To which region are you relocating?
      </Typography>
      <Stack spacing={2}>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => {
            handleButtonClick("Amsterdam")
          }}
        >
          Amsterdam
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => {
            handleButtonClick("Eindhoven")
          }}
        >
          Eindhoven
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => {
            handleButtonClick("Utrecht")
          }}
        >
          Utrecht
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          onClick={() => {
            handleButtonClick("Rotterdam")
          }}
        >
          Rotterdam
        </Button>
      </Stack>
    </>
  )
}
