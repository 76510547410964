import { SwiperButtons } from "../SwiperButtons"
import { BudgetSlider } from "../BudgetSlider"
import { useHousing } from "../../providers/housingState"
import { slideContent } from "../../assets/content/slideContent"
import { FirstOptionBlobSlide } from "../FirstOptionBlobSlide"
import { SlideBodyContent } from "../SlideBodyContent"
import { SlideBodyCTA } from "../SlideBodyCTA"

export const BudgetSlide = (): JSX.Element => {
  const { region } = useHousing()

  if (!region) return <></>

  return (
    <FirstOptionBlobSlide title="Setting a budget 💰">
      <SlideBodyContent>{slideContent[region].budget}</SlideBodyContent>
      <SlideBodyCTA>What are you willing to spend on housing?</SlideBodyCTA>
      <BudgetSlider />
      <SwiperButtons showNext={true} />
    </FirstOptionBlobSlide>
  )
}
