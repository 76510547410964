import { SwiperButtons } from "../SwiperButtons"
import { FurnishedCheckbox } from "../FurnishedCheckbox"
import { useHousing } from "../../providers/housingState"
import { slideContent } from "../../assets/content/slideContent"
import { SecondOptionBlobSlide } from "../SecondOptionBlobSlide"
import { SlideBodyContent } from "../SlideBodyContent"
import { SlideBodyCTA } from "../SlideBodyCTA"

export const FurnishedSlide = (): JSX.Element => {
  const { region } = useHousing()

  if (!region) return <></>

  return (
    <SecondOptionBlobSlide title="Adding furniture 🛋">
      <SlideBodyContent>{slideContent[region].furnished}</SlideBodyContent>
      <SlideBodyCTA>Do you want your new home to be furnished?</SlideBodyCTA>
      <FurnishedCheckbox />
      <SwiperButtons showPrevious={true} showNext={true} />
    </SecondOptionBlobSlide>
  )
}
