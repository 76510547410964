import { Typography } from "@mui/material"

type SlideBodyCTAProps = {
  children: string | JSX.Element
}

export const SlideBodyCTA = ({ children }: SlideBodyCTAProps) => {
  return (
    <Typography
      variant="body1"
      component="h2"
      sx={{ margin: "5px 0 15px", fontWeight: "bold" }}
    >
      {children}
    </Typography>
  )
}
