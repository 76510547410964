export const CopyrightFootnote = () => {
  return (
    <p
      style={{
        position: "absolute",
        bottom: "4px",
        right: "16px",
        color: "rgba(0, 0, 0, 0.5)",
        fontSize: "0.8rem",
      }}
    >
      All intellectual property rights reserved. © 2024
    </p>
  )
}
