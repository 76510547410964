import { styled, Typography } from "@mui/material"
import {
  StyledBlobContainer,
  StyledBlobContentContainer,
  StyledPageSplitStack,
} from "./SharedStyledComponents"
import { theme } from "../ui/materialUITheme"
import { ReactNode } from "react"
import useMediaQuery from "@mui/material/useMediaQuery"
import SvgBlobSecondOption from "../assets/blobs/blob-option-2-cropped.svg"
import SvgBlobSecondOptionGrey from "../assets/blobs/blob-option-2-cropped-grey.svg"
import { useASMLConfiguration } from "../ui/addCompanyStyles"

type SecondOptionBlobSlideProps = {
  title: string
  children: ReactNode
}

export const SecondOptionBlobSlide = ({
  title,
  children,
}: SecondOptionBlobSlideProps): JSX.Element => {
  const smallDevice = useMediaQuery(theme.breakpoints.down("lg"))

  const titleEl = <StyledTitle variant="h2">{title}</StyledTitle>

  return (
    <StyledPageSplitStack>
      <StyledSecondOptionBlobContainer>
        <StyledSecondOptionBlobContentContainer>
          {smallDevice && titleEl}
          {children}
        </StyledSecondOptionBlobContentContainer>
      </StyledSecondOptionBlobContainer>
      {!smallDevice && titleEl}
    </StyledPageSplitStack>
  )
}

const StyledTitle = styled(Typography)`
  flex-basis: 40%;
  text-align: center;
  padding: 20px;

  ${theme.breakpoints.down("lg")} {
    flex-basis: fit-content;
  }

  ${theme.breakpoints.between("md", "lg")} {
    margin-top: 50px;
  }
`

const StyledSecondOptionBlobContainer = styled(StyledBlobContainer)`
  max-width: 1600px;
  background-image: url(${useASMLConfiguration
    ? SvgBlobSecondOptionGrey
    : SvgBlobSecondOption});
  background-size: 70%;
  flex-basis: 60%;
  transition: 0.5s all ease;

  ${theme.breakpoints.down("lg")} {
    flex-grow: 1;
  }

  ${theme.breakpoints.down("md")} {
    background-size: 100%;
  }
`

const StyledSecondOptionBlobContentContainer = styled(
  StyledBlobContentContainer,
)`
  overflow: scroll;
  padding: 20px;
  max-height: 100vh;

  ${theme.breakpoints.down("md")} {
    width: 90%;
  }
`
