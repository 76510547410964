import { Card, List, ListItem, ListItemText, styled } from "@mui/material"
import { Map, Control } from "leaflet"
import { ReactElement, useEffect, useRef, useState } from "react"

type InteractiveMapLegendProps = {
  map: Map | null
}

export const InteractiveMapLegend = ({
  map,
}: InteractiveMapLegendProps): ReactElement => {
  const [legendAdded, setLegendAdded] = useState(false)
  const legendRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (map && !legendAdded) {
      const legend = new Control({ position: "bottomleft" })
      const currentlegendRef = legendRef.current
      if (currentlegendRef) {
        legend.onAdd = () => {
          return currentlegendRef
        }
      }
      legend.addTo(map)
      setLegendAdded(true)
    }
  }, [map, legendRef])

  return (
    <Card ref={legendRef}>
      <List dense>
        <ListItem>
          <ListItemColor backgroundColor="#c23728" />
          <ListItemText primary="(Almost) no options" />
        </ListItem>
        <ListItem>
          <ListItemColor backgroundColor="#db7966" />
          <ListItemText primary="Very few options" />
        </ListItem>
        <ListItem>
          <ListItemColor backgroundColor="#ebb5a9" />
          <ListItemText primary="Few options" />
        </ListItem>
        <ListItem>
          <ListItemColor backgroundColor="#b7cbe2" />
          <ListItemText primary="Some options" />
        </ListItem>
        <ListItem>
          <ListItemColor backgroundColor="#7aa7d4" />
          <ListItemText primary="More options" />
        </ListItem>
        <ListItem>
          <ListItemColor backgroundColor="#1984c5" />
          <ListItemText primary="Lots of options" />
        </ListItem>
      </List>
    </Card>
  )
}

type ListItemColorProps = {
  backgroundColor: string
}

const ListItemColor = styled("div", {
  shouldForwardProp: prop => prop !== "backgroundColor",
})<ListItemColorProps>(({ backgroundColor }) => ({
  borderRadius: "90px",
  marginRight: "8px",
  width: "20px",
  height: "20px",
  backgroundColor,
}))
