import { useHousing } from "../providers/housingState"
import { MapContainer, TileLayer, GeoJSON } from "react-leaflet"
import amsterdamJSON from "../assets/amsterdam-wijken.json"
import eindhovenJSON from "../assets/eindhoven-wijken.json"
import utrechtJSON from "../assets/utrecht-wijken.json"
import rotterdamJSON from "../assets/rotterdam-wijken.json"
import { SuburbGeoJson } from "../models/suburbGeoJson.interface"
import { LatLngTuple, Layer, Map } from "leaflet"
import { useMemo, useState } from "react"
import { InteractiveMapLegend } from "./InteractiveMapLegend"

export const InteractiveMap = (): JSX.Element => {
  const [map, setMap] = useState<null | Map>(null)
  const { getMapColorForSuburb, region } = useHousing()
  const style = (feature: SuburbGeoJson) => {
    const availabilityIndex = feature?.properties?.availabilityIndex ?? 0
    const budgetIndex = feature?.properties?.budgetIndex ?? 0
    const bedroomsIndex = feature?.properties?.bedroomsIndex ?? 0
    const squareMetersIndex = feature?.properties?.squareMetersIndex ?? 0
    const furnishedIndex = feature?.properties?.furnishedIndex ?? 0
    return {
      fillColor: getMapColorForSuburb(
        availabilityIndex,
        budgetIndex,
        bedroomsIndex,
        squareMetersIndex,
        furnishedIndex,
      ),
      fillOpacity: 0.75,
      color: "black",
      weight: 0.25,
    }
  }

  const handleOnEachFeature = (feature: SuburbGeoJson, layer: Layer) => {
    layer.on("mouseover", function () {
      layer
        .bindPopup(feature.properties.naam, {
          closeButton: false,
          autoPan: false,
        })
        .openPopup()
    })
  }

  if (!region) {
    return <></>
  }

  const zoom = useMemo(() => {
    if (region === "Utrecht" || region === "Rotterdam") return 12
    return 11
  }, [region])

  const centerCoordinates: LatLngTuple = useMemo(() => {
    if (region === "Eindhoven") return [51.4, 5.45]
    if (region === "Utrecht") return [52.07, 5.09]
    if (region === "Rotterdam") return [51.92, 4.49]
    return [52.36198, 4.898414]
  }, [region])

  const geoJsonData = useMemo(() => {
    if (region === "Eindhoven") return eindhovenJSON
    if (region === "Utrecht") return utrechtJSON
    if (region === "Rotterdam") return rotterdamJSON
    return amsterdamJSON
  }, [region])

  return (
    <MapContainer center={centerCoordinates} zoom={zoom} whenCreated={setMap}>
      <TileLayer
        attribution="Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ"
        url="https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
      />
      {
        <GeoJSON
          // @ts-ignore
          data={geoJsonData}
          // @ts-ignore
          style={style}
          onEachFeature={handleOnEachFeature}
        />
      }
      <InteractiveMapLegend map={map} />
    </MapContainer>
  )
}
