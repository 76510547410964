import { Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import { useHousing } from "../providers/housingState"

export const FurnishedCheckbox = (): JSX.Element => {
  const { furnished, setFurnished } = useHousing()

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFurnished(event.target.checked)
  }

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            color="default"
            checked={furnished}
            onChange={handleChange}
          />
        }
        label="Should be furnished"
      />
    </FormGroup>
  )
}
