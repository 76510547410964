import { Mark } from "@mui/base"
import { Slider, styled } from "@mui/material"

type CustomSliderProps = {
  value: number
  onChange: (newValue: number) => void
  marks?: Mark[]
  prefix?: string
  min?: number
  max?: number
}

export const CustomSlider = ({
  value,
  onChange,
  marks,
  prefix,
  min,
  max,
}: CustomSliderProps): JSX.Element => {
  const changeHandler = (event: Event, activeThumb: number | number[]) => {
    if (typeof activeThumb !== "number") return
    onChange(activeThumb)
  }

  return (
    <StyledSlider
      valueLabelDisplay="auto"
      valueLabelFormat={value => (
        <>
          {prefix}
          {value}
        </>
      )}
      value={value}
      onChange={changeHandler}
      step={null}
      marks={marks}
      min={min}
      max={max}
    />
  )
}

const StyledSlider = styled(Slider)({
  color: "#2a2136",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "1.5px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 40,
    height: 40,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#2a2136",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
})
