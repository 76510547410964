import { SwiperButtons } from "../SwiperButtons"
import { slideContent } from "../../assets/content/slideContent"
import { useHousing } from "../../providers/housingState"
import { FirstOptionBlobSlide } from "../FirstOptionBlobSlide"
import { SlideBodyContent } from "../SlideBodyContent"

export const OutsideSpaceSlide = (): JSX.Element => {
  const { region } = useHousing()

  if (!region) return <></>

  return (
    <FirstOptionBlobSlide title="Let's head outside 🌳">
      <SlideBodyContent>{slideContent[region].outsideSpace}</SlideBodyContent>
      <SwiperButtons showPrevious={true} showFinish={true} />
    </FirstOptionBlobSlide>
  )
}
