import { useLocation } from "react-router-dom"
import { StyledBlobContainer } from "../components/SharedStyledComponents"
import { RegionChoiceComponent } from "../components/RegionChoiceComponent"
import { IntroductionComponent } from "../components/IntroductionComponent"
import { theme } from "../ui/materialUITheme"

import SvgBlobCentered from "../assets/blobs/blob-centered-cropped.svg"
import SvgBlobCenteredGrey from "../assets/blobs/blob-centered-cropped-grey.svg"
import { Box, styled } from "@mui/material"
import { useASMLConfiguration } from "../ui/addCompanyStyles"
import { CopyrightFootnote } from "../components/CopyrightFootnote"

/** The IntroductionPage contains both the first view (intro text) and the second view (region choice).
 * This was done to make both pages use the same blob container, allowing us to animate any transition between
 * the two pages.
 */

export const IntroductionPage = (): JSX.Element => {
  const location = useLocation()

  const showRegionChoice = location.pathname.includes("region")

  const reverseFlexCSSProperties = {
    flexDirection: "column-reverse",
    gap: "5px",
  }

  return (
    <>
      <CenteredBlobContainer
        sx={
          showRegionChoice
            ? {
                backgroundSize: "400px",
                [theme.breakpoints.down("sm")]: { backgroundSize: "300px" },
              }
            : {
                backgroundSize: "40%",
                [theme.breakpoints.down("lg")]: { backgroundSize: "50%" },
                [theme.breakpoints.down("md")]: { backgroundSize: "60%" },
                [theme.breakpoints.down("sm")]: { backgroundSize: "100%" },
              }
        }
      >
        <CenteredBlob
          sx={
            showRegionChoice
              ? { gap: 0, flexDirection: "column" }
              : {
                  [theme.breakpoints.down("lg")]: { width: "50%" },
                  [theme.breakpoints.down("md")]: {
                    width: "60%",
                    ...(useASMLConfiguration ? reverseFlexCSSProperties : {}),
                  },
                  [theme.breakpoints.down("sm")]: {
                    width: "80%",
                    ...(!useASMLConfiguration ? reverseFlexCSSProperties : {}),
                  },
                }
          }
        >
          {!showRegionChoice && <IntroductionComponent />}
          {showRegionChoice && <RegionChoiceComponent />}
        </CenteredBlob>
      </CenteredBlobContainer>
      <CopyrightFootnote />
    </>
  )
}

const CenteredBlobContainer = styled(StyledBlobContainer)`
  max-width: 1600px;
  background-image: url(${useASMLConfiguration
    ? SvgBlobCenteredGrey
    : SvgBlobCentered});
  margin: 0 auto;
  transition: 0.5s all ease;
`

const CenteredBlob = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 40%;
  gap: ${useASMLConfiguration ? "10px" : "40px"};
  transition: 0.5s all ease;

  ${theme.breakpoints.down("lg")} {
    width: 50%;
  }

  ${theme.breakpoints.down("md")} {
    width: 60%;
  }

  ${theme.breakpoints.down("sm")} {
    width: 80%;
  }
`
