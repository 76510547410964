import React, { createContext, useContext, useState } from "react"
import { useASMLConfiguration } from "../ui/addCompanyStyles"

export type OutsideSpace = "balcony" | "garden" | "none"

export type Region = "Amsterdam" | "Eindhoven" | "Utrecht" | "Rotterdam"

type HousingStateProps = {
  region: Region | null
  setRegion: (newRegion: Region) => void
  budget: number
  setBudget: (newBudget: number) => void
  bedrooms: number
  setBedrooms: (newBedrooms: number) => void
  squareMeters: number
  setSquareMeters: (newSquareMeters: number) => void
  furnished: boolean
  setFurnished: (isFurnished: boolean) => void
  outsideSpace: OutsideSpace
  setOutsideSpace: (outsideSpace: OutsideSpace) => void
  getMapColorForSuburb: (
    availabilityIndex: number,
    budgetIndex: number,
    bedroomsIndex: number,
    squareMetersIndex: number,
    furnishedIndex: number,
  ) => string
}

const LocalStateContext = createContext({} as HousingStateProps)

type HousingStateProviderProps = {
  children: React.ReactNode
}

const HousingStateProvider = ({
  children,
}: HousingStateProviderProps): JSX.Element => {
  const [region, setRegion] = useState<Region | null>(
    useASMLConfiguration ? "Eindhoven" : "Amsterdam",
  )
  const [budget, setBudget] = useState(1500)
  const [bedrooms, setBedrooms] = useState(1)
  const [squareMeters, setSquareMeters] = useState(40)
  const [furnished, setFurnished] = useState(false)
  const [outsideSpace, setOutsideSpace] = useState<OutsideSpace>("none")

  /**
   * The higher the score is, the higher the chance that the GE will be successful in finding a house when trying this suburb.
   */
  const getSuccessScoreForSuburb = (
    availabilityIndex: number,
    budgetIndex: number,
    bedroomsIndex: number,
    squareMetersIndex: number,
    furnishedIndex: number,
  ) => {
    const weightedAvailability = availabilityIndex
    const weightedBudget = (1 + budget / 3000) * budgetIndex * 3
    const weightedBedrooms = (1 - bedrooms / 4) * bedroomsIndex
    const weightedSquareMeters = (1 - squareMeters / 90) * squareMetersIndex * 2
    const weightedFurnished = furnished ? furnishedIndex : 0
    const successScore =
      (weightedAvailability +
        weightedBudget +
        weightedBedrooms +
        weightedSquareMeters +
        weightedFurnished) /
      (furnished ? 8 : 7)
    return successScore
  }

  const getMapColorForSuburb = (
    availabilityIndex: number,
    budgetIndex: number,
    bedroomsIndex: number,
    squareMetersIndex: number,
    furnishedIndex: number,
  ) => {
    const successScore = getSuccessScoreForSuburb(
      availabilityIndex,
      budgetIndex,
      bedroomsIndex,
      squareMetersIndex,
      furnishedIndex,
    )

    if (successScore < 40) {
      return "#c23728"
    } else if (successScore < 70) {
      return "#db7966"
    } else if (successScore < 100) {
      return "#ebb5a9"
    } else if (successScore < 130) {
      return "#b7cbe2"
    } else if (successScore < 160) {
      return "#7aa7d4"
    } else {
      return "#1984c5"
    }
  }

  const value: HousingStateProps = {
    region,
    setRegion,
    budget,
    setBudget,
    bedrooms,
    setBedrooms,
    squareMeters,
    setSquareMeters,
    furnished,
    setFurnished,
    outsideSpace,
    setOutsideSpace,
    getMapColorForSuburb,
  }
  return (
    <LocalStateContext.Provider value={value}>
      {children}
    </LocalStateContext.Provider>
  )
}

const useHousing = (): HousingStateProps => {
  return useContext(LocalStateContext)
}

export { HousingStateProvider, useHousing }
