import { Mark } from "@mui/base"
import { useHousing } from "../providers/housingState"
import { CustomSlider } from "./CustomSlider"

export const squareMetersMarks: Mark[] = [
  {
    value: 30,
    label: "30",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 60,
    label: "60",
  },
  {
    value: 70,
    label: "70",
  },
  {
    value: 80,
    label: "80",
  },
  {
    value: 90,
    label: "90",
  },
]

export const SquareMetersSlider = (): JSX.Element => {
  const { squareMeters, setSquareMeters } = useHousing()

  return (
    <CustomSlider
      value={squareMeters}
      onChange={setSquareMeters}
      marks={squareMetersMarks}
      min={30}
      max={90}
    />
  )
}
